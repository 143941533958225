import { useEffect, useState } from "react";
import React from "react";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentosUsuario } from "../../../../../redux/documentos";
import DocumentosList from "./components/DocumentosList";
import Loading from "../../shared/components/Loading";
import { downloadDocumento } from "../../shared/services/documentos";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../../../../data/user';

export default function Empleado() {
    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed)
    const documentos = useSelector(state => {
        return state.documentos.entities
    });
    const status = useSelector(state => {
        return state.documentos.status
    });
    const dispatch = useDispatch();
    const [tipoDocumento, setTipoDocumento] = useState(-1);

    useEffect(() => {
        dispatch(fetchDocumentosUsuario(user.id));
    }, []);

    const handleChangeTipoDocumento = (e) =>{
        setTipoDocumento(parseInt(e.target.value));
    }

    const handleDownloadDocument = async(documento) => {
        const docResult = await downloadDocumento(documento.id);

        if(docResult) {
            const url = window.URL.createObjectURL(new Blob([docResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documento.nombre);
            document.body.appendChild(link);
            link.click();
        }
    }

    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }

    if(status.loading === 'succeed' || status.loading === 'rejected') {
        return (
            <div className="w-100">
                <Header
                    tipoDocumento={tipoDocumento}
                    onChangeTipoDocumento={handleChangeTipoDocumento}
                />

                <DocumentosList 
                    tipoDocumento={tipoDocumento}
                    documentos={documentos}
                    onDownload={handleDownloadDocument}
                />
            </div>
        );
    }
}