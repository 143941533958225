import { useState } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import ChangePasswordForm from './ChangePasswordForm';
import './micuenta.scss';

export default function MiCuenta() {
    const [tab, setTab] = useState('change-password');

    const handleChangeTab = (e, tab) => setTab(tab);

    return (
        <div className="content ajustes">
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Ajustes">
                    <Tab label="Cambiar contraseña" value="change-password" />
                </TabList>
                </Box>
                {/* <TabPanel value="change-password">
                    <ChangePasswordForm />
                </TabPanel> */}
            </TabContext>
        </div>
    );
}