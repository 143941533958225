import { faArrowLeft, faChevronDown, faCloud, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import React from "react";
import './doc_list.scss';
import FolderList from "./FolderList";
import List from "./List";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DocsComunesList from "./DocsComunesList";

export default function DocumentosList({
    documentos,
    usuario, 
    tipoDocumento,
    onSelectedFolder,
    onDelete,
    onDownload
}) {
    const [usersList, setUsersList] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);

    useEffect(() => {
        
        setUsersList(documentos)
        
        if(usuario.id !== -1) {
            filterFolders();
        } else setUsersList(documentos.documentosUser);

    }, [documentos, usuario, tipoDocumento]);
    

    const filterFolders = () => {
        const documentosAux = documentos.documentosUser.filter(doc => {
            return doc.empleado.id === usuario.id
        });

        setUsersList(documentosAux);
    }

    const handleSelectFolder = (folder) => { 
        setSelectedFolder(folder);
        onSelectedFolder(true);
    }

    const handleBackToFolders = () => {
        setSelectedFolder(null);
        onSelectedFolder(false);
    }

    return (
        <div className="w-100 documentos-list">
            <div className="w-100 d-flex align-items-end justify-content-between title mb-4">
                <div className="d-flex align-items-center">
                    <span>
                        <FontAwesomeIcon icon={faCloud} size="2x" color="#62B1F6" />
                    </span>
                    <h1 className="ms-2 d-flex align-items-center">
                        Documentos
                        { (selectedFolder) &&  
                            <>
                                {' > ' + selectedFolder.empleado.num_empleado} 
                                <small className="ms-2 mt-1">({selectedFolder.empleado.nombre})</small>
                            </>
                        }
                    </h1>
                </div>
                
                
                <button className="btn btn-add" onClick={() => handleBackToFolders()} style={{opacity: (selectedFolder) ? '100%' : 0, pointerEvents: (selectedFolder) ? 'auto' : 'none' }}>
                    <span className="add">
                        <FontAwesomeIcon icon={faArrowLeft} size="1x" className="action" color="#215732" />
                    </span>
                    Atrás
                </button>
            </div>

            { (!selectedFolder) ?
                <div className="w-100 d-flex flex-wrap align-items-center">
                    <div className="w-100 mb-4 comunes">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" className="action" color="#215732" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className="w-100 d-flex align-items-center">
                                    <FontAwesomeIcon icon={faFolder} size="2x" color="#F8D775" />
                                    <span className="ms-2">ARCHIVOS COMUNES</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="w-100">
                                    <DocsComunesList
                                        list={documentos.documentosComunes}
                                        onDelete={onDelete}
                                        onDownload={onDownload}
                                    /> 
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    
                    { (usersList?.length > 0) ?
                        <FolderList 
                            list={usersList}
                            onSelectedFolder={handleSelectFolder}
                        />
                        : <p>No hay carpetas.</p>
                    }
                    
                </div>
                :
                <List
                    list={selectedFolder.documentos}
                    tipoDocumento={tipoDocumento}
                    onDelete={onDelete}
                    onDownload={onDownload}
                /> 
            }
        </div>
    );
}