import { useEffect, useState } from "react";
import React from "react";
import Header from "./components/Header";
import InfoModal from "../../shared/components/InfoModal";
import DocumentoForm from "./components/DocumentoForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentos, removeDocumento, saveDocumento } from "../../../../../redux/documentos";
import DocumentosList from "./components/DocumentosList";
import Loading from "../../shared/components/Loading";
import ConfirmModal from "../../shared/components/ConfirmModal";
import { downloadDocumento } from "../../shared/services/documentos";

export default function Admin() {
    const documentos = useSelector(state => {
        return state.documentos.entities
    });
    const status = useSelector(state => {
        return state.documentos.status
    });
    const dispatch = useDispatch();
    // TODO: understand why is set to -1
    const [usuario, setUsuario] = useState({id: -1, nombre: 'Todos'});
    const [tipoDocumento, setTipoDocumento] = useState(-1);
    const [deleteId, setDeleteId] = useState(null);
    const [openNewDocumento, setOpenNewDocumento] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        dispatch(fetchDocumentos());
    }, []);

    const handleChangeUser = (e, user) => {
        if(user) {
            setUsuario(user);
        } else {
            setUsuario({id: -1, nombre: 'Todos'});
        }
    }
    const handleChangeTipoDocumento = (e) =>{
        setTipoDocumento(parseInt(e.target.value));
    }

    const handleOpenNewDocumento = () => setOpenNewDocumento(true);
    const handleCloseNewDocumento = () => setOpenNewDocumento(false);

    const handleSubmitDocumento = (data) => {
        dispatch({type: 'loading/set', payload: true});
        dispatch(saveDocumento(data))
        setOpenNewDocumento(false);
    }

    const handleSetSelectedFolder = (selected)  => setSelectedFolder(selected);

    const handleOpenConfirmDelete = (documentoId) => {
        setOpenDelete(true);
        setDeleteId(documentoId);
    }
    const handleCloseConfirmDelete = () => setOpenDelete(false);
    const handleConfirmDelete = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});
            dispatch(removeDocumento(deleteId))
        }

        setOpenDelete(false);
        setDeleteId(null);
    }

    const handleDownloadDocument = async(documento) => {
        const docResult = await downloadDocumento(documento.id);

        if(docResult) {
            const url = window.URL.createObjectURL(new Blob([docResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documento.nombre);
            document.body.appendChild(link);
            link.click();
        }
    }

    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }

    if(status.loading === 'succeed' || status.loading === 'rejected') {
        return (
            <div className="w-100">
                <Header
                    user={usuario}
                    tipoDocumento={tipoDocumento}
                    selectedFolder={selectedFolder}
                    onOpenNew={handleOpenNewDocumento}
                    onChangeUser={handleChangeUser}
                    onChangeTipoDocumento={handleChangeTipoDocumento}
                />

                <DocumentosList 
                    usuario={usuario}
                    tipoDocumento={tipoDocumento}
                    documentos={documentos}
                    onSelectedFolder={handleSetSelectedFolder}
                    onDelete={handleOpenConfirmDelete}
                    onDownload={handleDownloadDocument}
                />

                { (openNewDocumento) &&
                    <InfoModal
                        title={"Añadir nuevo documento"}
                        content={
                            <DocumentoForm
                                onSubmitDocumento={handleSubmitDocumento}
                            />
                        }
                        state={openNewDocumento}
                        width={'sm'}
                        onClose={handleCloseNewDocumento}
                    />
                }

                { (openDelete) &&
                    <ConfirmModal
                        description={"¿Estás seguro de eliminar el documento?"}
                        title={"Eliminar documento"}
                        state={openDelete}
                        onClose={handleCloseConfirmDelete}
                        onConfirmAction={handleConfirmDelete}
                    />
                }
            </div>
        );
    }
}