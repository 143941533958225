
import { createBrowserRouter } from 'react-router-dom';
// import MainLayout from '../views/MainLayout';
// import ReglasDesguaces from '../views/MainLayout/ReglasDesguaces';
// import ReglasPiezas from '../views/MainLayout/ReglasPiezas';
// import TablaPricing from '../views/MainLayout/TablaPricing';
// import LogsPage from '../views/MainLayout/LogsPage';

import Ajustes from "../views/Main/components/Ajustes";
import Documentos from "../views/Main/components/Documentos";
import MiCuenta from "../views/Main/components/MiCuenta";
import Dashboard from "../views/Main/components/Dashboard";
import Empleados from "../views/Main/components/Empleados";
import FichaEmpleado from "../views/Main/components/Empleados/FichaEmpleado";
import Inicio from '../views/Main/components/Inicio';
import ProtectedRoute from './ProtectedRoute';
import Main from '../views/Main';

import React from 'react';


export const router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute ><Main /></ProtectedRoute>,
        loader: ({ request }) => {
            return null
        },
        shouldRevalidate: () => true,
        children: [
            {    
                path: '/',
                element: <Inicio />
            },
            {
                path: '/ajustes',
                element: <Ajustes />
            },
            {
                path: '/documentos',
                element: <Documentos />
            },
            {
                path: '/mi-cuenta',
                element: <MiCuenta /> 
            },
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: '/empleados',
                element: <Empleados />
            },
            {
                path: '/empleado/:userId',
                element: <FichaEmpleado />
            }
        ]
    }
]);
