import axios from 'axios';
import { Config } from '../config';

export const API = axios.create({
    baseURL: Config.recomotor.apiUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});



