class Company {
    constructor(id, name) {
      this.id = id;
      this.name = name;
    }
  }
  
  const Companies = {
    RECOMOTOR: new Company(1, 'Recomotor'),
    DESGUACE_VINAROS: new Company(2, 'Desguace Vinaros'),
    DESGUACE_MICAL: new Company(3, 'Desguace Mical'),
    DESGUACE_SEGOHON: new Company(4, 'Desguace Segohon'),
    DESGUACE_ONLINE: new Company(5, 'Desguace Online sl'),
  };
  
  // Utility functions
  const getCompanyById = (id) => Object.values(Companies).find(company => company.id === id);
  const getCompanyByName = (name) => Object.values(Companies).find(company => company.name === name);
  
  export {
    Company,
    Companies,
    getCompanyById,
    getCompanyByName,
  };
  