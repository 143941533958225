export const SidebarData = [
    {
        title: 'Inicio',
        path: '/',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Documentos',
        path: '/documentos',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        cName: 'nav-text',
        permission: 7
    },
    {
        title: 'Empleados',
        path: '/empleados',
        cName: 'nav-text',
        permission: 7
    },
    {
        title: 'Empleado',
        path: '/empleado',
        cName: 'nav-text',
        permission: 0
    },
]