class Absence {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}
  
const Absences = {
  VACACIONES: new Absence(1, 'Vacaciones'),
  AUSENCIA_JUSTIFICADA: new Absence(2, 'Ausencia Justificada'),
  ENFERMEDAD: new Absence(3, 'Enfermedad'),
  TELETRABAJO: new Absence(4, 'Teletrabajo'),
  MATERNIDAD_PATERNIDAD: new Absence(5, 'Maternidad/Paternidad'),
  OTROS: new Absence(6, 'Otros'),
  FORMACION: new Absence(7, 'Formación'),
  ASUNTOS_PROPIOS: new Absence(8, 'Asuntos propios'),
  CUMPLEANOS: new Absence(9, 'Cumpleaños'),
};
  
  // Utility functions
const getAbsenceById = (id) => Object.values(Absences).find(absence => absence.id === id);
const getAbsenceByName = (name) => Object.values(Absences).find(absence => absence.name === name);

export {
  Absence,
  Absences,
  getAbsenceById,
  getAbsenceByName,
};
  