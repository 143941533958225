import { useEffect } from "react";
// import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer} from 'react-toastify';

// import Inicio from './components/Inicio';
import Loading from "./components/shared/components/Loading";
import Config from "./components/shared/components/Config";
import Navbar from "./components/shared/components/Navbar";
import { fetchReadOnlyData } from "../../redux/read_only";
// import Ajustes from "./components/Ajustes";
// import Documentos from "./components/Documentos";
import LoadingBackdrop from "./components/shared/components/LoadingBackdrop";
// import MiCuenta from "./components/MiCuenta";
// import Dashboard from "./components/Dashboard";
// import Empleados from "./components/Empleados";
// import FichaEmpleado from "./components/Empleados/FichaEmpleado";
import { useKeycloak } from '@react-keycloak/web';
import User from '../../data/user';
import { Outlet } from "react-router-dom";

const Main = ({onLogout}) => {
    // const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const user = new User(keycloak.token, keycloak.tokenParsed)
    const status = useSelector(state => {
        return state.read_only.status
    });
    const loading = useSelector(state => {
        return state.loading
    })

    useEffect(() => {
        // MAKE INITIAL LOAD OF READ ONLY DATA ON REDUX STORE
        dispatch(fetchReadOnlyData(
            (user.roles.includes('admin') || user.roles.includes('rrhh')) ? null
            : user.empresa_id
        ))
    }, []);

      return (
        <div className="dashboard-container">
            <Navbar onLogout={onLogout} />

            <Config />

            { (status.loading === 'pending' || status.loading === 'idle') &&
                <Loading />
            }

            
            { (status.loading === 'succeed' || status.loading === 'rejected') &&
                <Outlet/>
            }

            { loading && <LoadingBackdrop /> }

            <ToastContainer
                containerId="dashboard"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </div> 
    );
}

/**

 */

export default Main