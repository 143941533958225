import { faCheck, faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from "../../../shared/components/InfoModal";
import { toast } from "react-toastify";
import Loading from "../../../shared/components/Loading";
import { enableDisableUser, newUser, updateUser } from "../../../shared/services/users";
import UserForm from "./components/UserForm";
import moment from "moment";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { getActions } from "./actions";
import { MenuItem, Select } from "@mui/material";
import { fetchUsers } from "../../../../../../redux/users";
import { getCompanyById } from "../../../../../../data/company";
export default function UserTab() {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [userList, setUserList] = useState([]);
    const [active, setActive] = useState(-1);
    const [currentUser, setCurrentUser] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const users = useSelector(state => {
        return state.users.entities
    });
    const usersStatus = useSelector(state => {
        return state.users.status
    });

    useEffect(() => {
        setUserList(users);
    }, [users]);

    const handleNew = () => { 
        setCurrentUser(null);
        setOpenModal(true); 
    }
    const handleEdit = (user) => { 
        setCurrentUser(user);
        setOpenModal(true); 
    }
   
   const handleCancel = () => {
        setCurrentUser(null);
        setOpenModal(false); 
   }

   const handleAction = (action, user) => {
    if(action === 'edit') {
        handleEdit(user)
    } else  if(action === 'enable' || action === 'disable') {
        handleToggle(user.id)
    }
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    if(value === "") {
        setUserList(users);
        setActive(-1);
    } else {
        const newList = userList.filter(u => u.nombre?.toUpperCase().includes(value.toUpperCase()))
        setUserList(newList);
    }
  }

  const handleChangeActive = (e) => {
    const value = parseInt(e.target.value);
    setActive(value);

    if(value === -1) {
        setUserList(users);
    } else {
        const newList = userList.filter(u => u.active === value);
        setUserList(newList);
    }
  }

   const handleToggle = async(userId) => {
        const response = await enableDisableUser(userId);

        if(response && response.success) {
            toast.success(response.message)
            dispatch(fetchUsers())
        }
   }
   const handleSubmit = async(data) => { 
        let response;

        if(currentUser) {
            response = await updateUser(data);
        } else {
            delete data.id;
            response = await newUser(data);
        }

        if(response && response.success) {
            setCurrentUser(null); 
            setOpenModal(false); 
            dispatch(fetchUsers()); 
        }
    }
  
    return (
        <div className="w-100">
            <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="position-relative ms-auto me-3">
                    <input 
                        type="text" 
                        name="searcher" 
                        className="form-control" 
                        value={search} 
                        onChange={(handleSearch)}
                    />
                    <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                </div>

                <div className="me-3">
                    <Select
                        size="small"
                        name="active"
                        id="active"
                        onChange={handleChangeActive}
                        value={active}>
                            <MenuItem 
                                key={'filter-active--1'}
                                value={-1}>
                                {"Todos"}
                            </MenuItem>
                            <MenuItem 
                                key={'filter-active-0'}
                                value={0}>
                                {"No"}
                            </MenuItem>
                            <MenuItem 
                                key={'filter-active-1'}
                                value={1}>
                                {"Si"}
                            </MenuItem>
                    </Select>
                </div>

                <button className="btn btn-add" onClick={() => handleNew()}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
            </div>

            { (usersStatus.loading === 'pending' || usersStatus.loading === 'idle') &&
                <Loading />
            }

            { (usersStatus.loading === 'succeed' || usersStatus.loading === 'rejected') &&
                <div className="w-100 table-responsive mt-3">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>nombre</th>
                                <th className="text-center">dni</th>
                                <th className="text-center">email</th>
                                <th className="text-center">teléfono</th>
                                <th className="text-center">primer día</th>
                                <th className="text-center">último día</th>
                                <th className="text-center">cumpleaños</th>
                                <th className="text-center">DEP</th>
                                <th className="text-center">tipo</th>
                                <th className="text-center">rol</th>
                                <th className="text-center">empresa</th>
                                <th className="text-center">dias totales</th>
                                <th className="text-center">activo</th>
                                <th className="text-center">acciones</th>
                            </tr>
                        </thead>

                        <tbody>
                            { userList?.map(user => {
                                return (
                                    <tr key={'user-' + user.id}>
                                        <td>{user.num_empleado}</td>
                                        <td className="text-uppercase">{user.nombre}</td>
                                        <td className="text-uppercase text-center">{user.dni}</td>
                                        <td className="text-center">{user.email}</td>
                                        <td className="text-center">{user.telefono}</td>
                                        <td className="text-center">{user.start_on !== null ? moment(user.start_on).format('DD-MM-YYYY') : '-'}</td>    
                                        <td className="text-center">{user.end_on !== null ? moment(user.end_on).format('DD-MM-YYYY') : '-'}</td>    
                                        <td className="text-center">{user.birthday !== null ? moment(user.birthday).format('DD-MM-YYYY') : '-'}</td>
                                        <td className="text-center text-uppercase">{user.departamento.nombre}</td>
                                        <td className="text-center text-uppercase">{user.tipoUser.nombre}</td>
                                        <td className="text-center text-uppercase">{user.rol?.nombre}</td>
                                        <td className="text-center text-uppercase">{getCompanyById(user.departamento.empresa_id).name}</td>
                                        <td className="text-center">{user.dias_totales}</td>
                                        <td className="text-center">
                                            { (user.active) ?
                                                <FontAwesomeIcon icon={faCheck} size="1x" color="#00986C" />
                                                : 
                                                <FontAwesomeIcon icon={faTimes} size="1x" color="#dc3545" />
                                            }
                                        </td>
                                        <td className="text-center">
                                            <ActionsMenu 
                                                options={getActions(user)}
                                                onAction={(action) => handleAction(action, user)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            }
        
            { (openModal) &&
                <InfoModal 
                    title={(!currentUser) ? "Nuevo Usuario" : "Editar Usuario"}
                    content={
                        <UserForm 
                            user={currentUser}
                            onSubmitUser={handleSubmit}
                        />
                    } 
                    state={openModal}
                    width={'sm'}
                    onClose={handleCancel}
                />
            }
        </div>
    )
}