export const Config = {
    app: {
        version: `${process.env.REACT_APP_VERSION || 'dev'}`,
    },
    keycloak: {
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    },
    recomotor: {
        apiUrl: process.env.REACT_APP_API_BASE
    }
};
