import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "./utils";
import { getGeneralMetrics, getUserMetrics } from "../views/Main/components/shared/services/dashboard";

const initialDashboard = { 
    userMetrics: null,
    generalMetrics: null,
};

export const superDashboardReducer = (state = initialDashboard, action) => {
    switch(action.type){
        case "dashboard/fullfilled": {
            if(action.payload.type === 'general') {
                const newDash = {...state, generalMetrics: action.payload.data }
                return newDash;
            } else {
                const newDash = {...state, userMetrics: action.payload.data }
                return newDash;
            }
        }
        default:
            return state
    }
}

const asyncDashboard = makeAsyncTypes('dashboard');
const [setPending, setFullFilled, setError ] = asyncMac(asyncDashboard);

export const dashboardFetchingReducer = makeFetchingReducer([
    'dashboard/pending', 
    'dashboard/fullfilled', 
    'dashboard/rejected'
]);

export const fetchUserMetrics = (userId, startDate, endDate, view) => async dispatch => {
    dispatch(setPending());
    try {
        const response = await getUserMetrics(userId, startDate, endDate, view);
        const data = await response.data;
        dispatch(setFullFilled({type: 'user', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const fetchGeneralMetrics = (startDate, endDate, view) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getGeneralMetrics(startDate, endDate, view);
        const data = await response.data;
        dispatch(setFullFilled({type: 'general', data: data}));
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const dashboardReducer = superDashboardReducer;
export const dashboardStatusReducer = dashboardFetchingReducer