import { RouterProvider } from "react-router-dom";
// import { useKeycloak } from '@react-keycloak/web';
// import './styles/main.scss';
// import Login from './views/Login';
// import Main from './views/Main';
// import useToken from './useToken';
// import './styles/main.scss';
// import { RouterProvider } from 'react-router-dom';
import { router } from './routes/routes';


function App() {
  // const navigate = useNavigate();

  // const { token, setToken } = useToken();

  // const handleLogout = () => {
  //   setToken(null);
  //   localStorage.clear();
  //   navigate('/login', { replace: true });
  // }


  return <RouterProvider router={router} />;
}

export default App;
